import { useState, useRef, useEffect } from 'react';

import _ResizeObserver from 'resize-observer-polyfill';

export const useMeasure = () => {
  const ref = useRef();
  const isClient = typeof window === 'object';

  const [bounds, setBounds] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [resizeO] = useState(() => {
    return isClient
      ? new _ResizeObserver(([entry]) => {
          setBounds(entry.contentRect);
        })
      : null;
  });

  useEffect(() => {
    if (ref.current) {
      resizeO.observe(ref.current);
    }
    return () => {
      resizeO.disconnect();
    };
  }, [resizeO]);

  return [{ ref }, bounds];
};

export const useTailPadTop = (size) => {
  switch (size) {
    case '1':
      return 'pt-1';
    case '2':
      return 'pt-2';
    case '3':
      return 'pt-3';
    case '4':
      return 'pt-4';
    case '5':
      return 'pt-5';
    case '6':
      return 'pt-6';
    case '8':
      return 'pt-8';
    case '10':
      return 'pt-10';
    case '12':
      return 'pt-12';
    case '16':
      return 'pt-16';
    case '20':
      return 'pt-20';
    case '24':
      return 'pt-20 md:pt-24';
    case '32':
      return 'pt-20 md:pt-32';
    case '40':
      return 'pt-20 md:pt-40';
    case '48':
      return 'pt-20 md:pt-48';
    case '56':
      return 'pt-20 md:pt-56';
    case '64':
      return 'pt-20 md:pt-64';
    default:
      return null;
  }
};

export const useOfferLayout = (layout) => {
  switch (layout) {
    case 'left':
      return 'lg:justify-start';
    case 'right':
      return 'lg:justify-end';
    default:
      return 'lg:justify-center';
  }
};

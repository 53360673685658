export const useTailPadBot = (size) => {
  switch (size) {
    case '1':
      return 'pb-1';
    case '2':
      return 'pb-2';
    case '3':
      return 'pb-3';
    case '4':
      return 'pb-4';
    case '5':
      return 'pb-5';
    case '6':
      return 'pb-6';
    case '8':
      return 'pb-8';
    case '10':
      return 'pb-10';
    case '12':
      return 'pb-12';
    case '16':
      return 'pb-16';
    case '20':
      return 'pb-20';
    case '24':
      return 'pb-20 md:pb-24';
    case '32':
      return 'pb-20 md:pb-32';
    case '40':
      return 'pb-20 md:pb-40';
    case '48':
      return 'pb-20 md:pb-48';
    case '56':
      return 'pb-20 md:pb-56';
    case '64':
      return 'pb-20 md:pb-64';
    default:
      return null;
  }
};

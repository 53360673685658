export const useTableCols = (cols) => {
  switch (cols) {
    case 1:
      return 'grid-cols-c1 md:grid-cols-2';
    case 2:
      return 'grid-cols-c2 md:grid-cols-3';
    case 3:
      return 'grid-cols-c3 md:grid-cols-4';
    case 4:
      return 'grid-cols-c4 md:grid-cols-5';
    default:
      return null;
  }
};
